<template>
	<div class="content">
		<Form :model="search" :label-width="120">
			<Row>
				<Col span="8">
				<FormItem label="订单号">
					<Input v-model="search.orderNo" placeholder="请输入订单号"></Input>
				</FormItem>
				</Col>
				<Col span="8">
				<FormItem label="支付电话">
					<Input v-model="search.phone" placeholder="请输入支付电话"></Input>
				</FormItem>
				</Col>
		
				<Col span="8">
				<FormItem label="订单状态">
					<Select v-model="search.orderStatus">
						<Option value="A_NO_PAY">未支付</Option>
						<Option value="B_PAYING">支付中</Option>
						<Option value="C_SUCCESS">支付成功</Option>
						<Option value="D_FAILED">支付失败</Option>
						<!-- <Option value="E_REFUNDING">退款中</Option>
						<Option value="F_REFUND_PART_SUCCESS">部分退款成功</Option>
						<Option value="H_REFUND_SUCCESS">退款成功</Option>
						<Option value="G_REFUND_FAILED">退款失败</Option> -->
					</Select>
				</FormItem>
				</Col>
				<Col span="8">
				<FormItem label="支付平台">
					<Select v-model="search.payPlatform">
						<Option value="WX">微信</Option>
						<Option value="ALIPAY ">支付宝</Option>
					</Select>
				</FormItem>
				</Col>
				<Col span="8">
				<FormItem label="支付开始时间">
					<DatePicker v-model="search.payTimeStart" type="datetime" placeholder="请选择支付开始时间"
						style="width: 100%"></DatePicker>
				</FormItem>
				</Col>
				<Col span="8">
				<FormItem label="支付结束时间">
					<DatePicker v-model="search.payTimeEnd" type="datetime" placeholder="请选择支付结束时间"
						style="width: 100%"></DatePicker>
				</FormItem>
				</Col>
				<Col span="24" style="text-align: right;">
				<FormItem>
					<Button type="primary" class="margin_left_10" @click.native="getOrderPage">查询</Button>
				</FormItem>
		
		
				</Col>
			</Row>
		</Form>
		<Table :columns="columns" :data="orderData">
		
			<template slot-scope="{ row }" slot="action">
		
				<!-- <Button size="small" type="error" @click.native="delmerchant(row)"
					class="margin_right_10">详情</Button> -->
			</template>
		</Table>
		<Page class="margin_10" v-if="orderPage.pages > 1" :total="orderPage.total" :current="orderPage.pageNum"
			@on-change="changePage" show-elevator show-sizer />
	</div>
</template>
<script>
	import {
		orderPage,
	} from '@/api/merchant/merchant.js'
	export default {
		name: 'merchantOrder',

		data() {
			return {
				
				search: {
					merchantAppid:JSON.parse(sessionStorage.getItem('merchantInfo')).appid,
					orderNo: '',
					orderStatus: 'A_NO_PAY',
					payPlatform: 'WX',
					payTimeStart: '',
					payTimeEnd: '',
					phone: ''
				},
				
				orderPage: {
					pageNum: 1,
					pageSize: 10,
					total: 0,
					pages: 1
				},
				columns: [{
						title: 'openid',
						key: 'openid'
					},
					{
						title: '订单号',
						key: 'orderNo'
					},
					{
						title: '支付金额',
						key: 'amount'
					},

					{
						title: '支付电话',
						key: 'phone',
						render: (h, params) => {
							console.log(params.row)
							if (params.row.weChatMiniProgramUser) {
								return h('div', params.row.weChatMiniProgramUser.phone);
							} else {
								return h('div', '');
							}

						}
					},
					{
						title: '订单状态',
						key: 'orderStatus',
						render: (h, params) => {
							switch (params.row.orderStatus) {
								case 'A_NO_PAY':
									return h('div', '未支付');
									break;
								case 'B_PAYING':
									return h('div', '支付中');
									break;
								case 'C_SUCCESS':
									return h('div', '支付成功');
									break;
								case 'D_FAILED':
									return h('div', '支付失败');
									break;
								case 'E_REFUNDING':
									return h('div', '退款中');
									break;
								case 'F_REFUND_PART_SUCCESS':
									return h('div', '部分退款成功');
									break;
								case 'H_REFUND_SUCCESS':
									return h('div', '退款成功');
									break;
								case 'G_REFUND_FAILED':
									return h('div', '退款失败');
									break;
								default:
									break;
							}


						}
					},
					{
						title: '支付平台',
						key: 'payPlatform',
						render: (h, params) => {
							switch (params.row.orderStatus) {
								case 'WX':
									return h('div', '微信');
									break;
								case 'ALIPAY':
									return h('div', '支付宝');
									break;

							}
						}
					},

					{
						title: '支付时间',
						key: 'payTime',
						render: (h, params) => {
							if (params.row.payTime) {
								return h('div', this.$dayjs(params.row.payTime).format('YYYY-MM-DD HH:mm:ss'));
							} else {
								return h('div', '');
							}

						}
					},
					{
						title: '更新人',
						key: 'updater'
					},
					{
						title: '操作',
						slot: 'action'
					}
				],
				
				orderData: []
			}
		},
		created() {
			
			this.getOrderPage()
		},
		methods: {
			
			getOrderPage() {
				if (!this.search.merchantAppid) {
					this.$Message.warning('请先选择商户！')
					return false
				}
				let page = {
					pageNum: this.orderPage.pageNum - 1,
					pageSize: this.orderPage.pageSize
				}
				let _search = Object.assign({}, this.search, page);

				_search.payTimeStart = _search.payTimeStart ? new Date(_search.payTimeStart).getTime() : ''
				_search.payTimeEnd = _search.payTimeEnd ? new Date(_search.payTimeEnd).getTime() : ''
				orderPage(_search).then(res => {
					console.log(32, res)
					this.orderData = res.data.data.data

					this.orderPage = {
						total: res.data.data.total,
						pageSize: res.data.data.pageSize,
						pageNum: res.data.data.pageNum,
						pages: res.data.data.pages
					}
				}).catch(reason => {
					console.log(reason);
				})
			},
			changePage(pageNum) {
				this.orderPage.pageNum = pageNum;
				this.getOrderPage();
			},
			
		}
	}
</script>

<style scoped lang="less">
	.content {
		
		.contentLeft {
			width: 350px;
			height: 100%;
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}

		.contentRight {
			flex: 1;
		}
	}
</style>